import React, { useEffect, useState } from 'react';
import { useUser } from '../../contexts/UserContext';
import PropTypes from 'prop-types';
import { SpanAvatarInitial } from './style';
import { AvatarInitialWrapper } from './style';
import { db } from '../../helpers/firebaseHelper';

const AvatarInitials = ({ customStyle, customSpanStyle, user = undefined }) => {
  const { state } = useUser();
  const [initials, setInitials] = useState('');

  useEffect(() => {
    if (user !== undefined) {
      setInitials(user.prenom[0] + user.name[0]);
    } else if (state.userId !== '') {
      setInitials(state.prenom[0] + state.name[0]);
    }
  }, [state]);

  return (
    <AvatarInitialWrapper style={{ ...customStyle }}>
      <SpanAvatarInitial style={{ ...customSpanStyle }}>
        {initials.toUpperCase()}
      </SpanAvatarInitial>
    </AvatarInitialWrapper>
  );
};

AvatarInitials.propTypes = {
  customSpanStyle: PropTypes.any,
  spanStyle: PropTypes.any,
  user: PropTypes.any,
};

export default AvatarInitials;
