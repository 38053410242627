import styled from 'styled-components';
import colors from '../../theme/colors';
import bi from '@core/ui/static/images/locales/bi.svg';
import en from '@core/ui/static/images/locales/en.svg';
import fr from '@core/ui/static/images/locales/fr.svg';

export const Flag = styled('span')`
  display: inline-block;
  width: 20px;
  height: 13px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  margin-left: 5px;
  &.en {
    background-image: url(${en});
  }
  &.bi {
    background-image: url(${bi});
  }
  &.fr {
    background-image: url(${fr});
  }
`;
export const FlagLabel = styled('span')`
  margin-left: 5px;
  font-size: 14px;
  margin-top: -1px;
  margin-right: 5px;
  & + i {
    margin-top: 0 !important;
  }
`;

export const MenuWrapper = styled('nav')`
  display: block;
  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }
`;

export const MenuItemWrapper = styled('div')`
  display: block;
  color: #595959;
  a span.item-menu,
  .button-search-header {
    transition-duration: 0.4s;
  }
  &:hover {
    a span.item-menu,
    .button-search-header {
      color: ${colors.primaryHover};
    }
  }
  &.active {
    a span.item-menu {
      color: ${colors.primaryHover};
    }
    &:after {
      content: ' ';
      display: block;
      background-color: #00bcd4;
      height: 3px;
      width: 15px;
      border-radius: 1.5px;
      margin-top: 5px;
      margin-left: 30px;
      position: absolute;
    }
  }
  a {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: #595959;
    position: relative;
    &:hover {
      color: #00bcd4;
    }
    span.item-menu {
      font-family: Poppins, 'Sans Serif';
      font-weight: 500;
      font-size: 14px;
      color: #18c2d8;
    }
    .unread-msg {
      background-color: #ed7877;
      color: white;
      margin: auto;
      text-align: center;
      border-radius: 50%;
      position: absolute;
      line-height: 15px;
      height: 15px;
      width: 15px;
      font-weight: bold;
      font-size: 10px;
      left: 13px;
      top: -1px;
    }
  }
  @media (min-width: 768px) {
    display: inline-block;
    padding-left: ${props => props.paddingX || '1rem'};
    padding-right: ${props => props.paddingX || '1rem'};
    flex-shrink: 0;
  }
  @media (max-width: 1126px) {
    &.item-my-classifieds {
      display: none;
    }
  }
  @media (max-width: 962px) {
    &.item-my-favorites {
      display: none;
    }
  }
`;
