import Cookies from 'js-cookie';
import nextCookie from 'next-cookies';

const FIREBASE_COOKIE = '__session';

const NEXT_LOCALE_COOKIE = 'NEXT_LOCALE';

const getCookieFromBrowser = key => {
  return Cookies.get(key);
};

const getCookieFromServer = (ctx, key = 'id_token') => {
  if (!ctx) {
    return null;
  }
  const cookies = nextCookie(ctx);

  if (cookies && cookies.hasOwnProperty(key)) {
    return cookies[key];
  }

  return null;

  /*const value = cookies && cookies[key] ? cookies[key] : false;
    if (!value) return null;
    return value;*/
};

export const getCookie = (key, context = null) => {
  return !context
    ? getCookieFromBrowser(key)
    : getCookieFromServer(context, key);
};

export const setCookie = (key, value) => {
  Cookies.set(key, JSON.stringify(value), { expires: 7, sameSite: 'strict' });
};

export const removeCookie = key => {
  Cookies.remove(key);
};

export const setFirebaseCookie = (key, value) => {
  // get cookie from __session using getCookie function
  // parse the data from cookie
  // assign the token to the key
  // set user data in global cookie only keep FIREBASE_COOKIE (__session) for specific data
  // Explanation: if __session key in cookie change the cache is destroyed

  try {
    const cookieData = getCookie(FIREBASE_COOKIE);
    const data = cookieData ? JSON.parse(cookieData) : {};
    data[key] = value;
    setCookie(FIREBASE_COOKIE, data);
  } catch (error) {
    console.error(error, 'setFirebaseCookie');
  }
};

export const removeFirebaseCookie = key => {
  // get cookie from __session  using getCookie function
  // parse the data from cookie
  // remove the key from the data
  // call set cookie function with the new data
  try {
    const cookieData = getCookie(FIREBASE_COOKIE);
    const data = cookieData ? JSON.parse(cookieData) : {};
    if (data && data.hasOwnProperty(key)) {
      delete data[key];
      setCookie(FIREBASE_COOKIE, data);
    } else {
    }
  } catch (error) {
    console.error(error, 'removeFirebaseCookie');
  }
};

export const getFirebaseCookie = (key, context = null) => {
  // get cookie from __session using getCookie function
  // parse the data from cookie
  // get the relatedData using the key
  try {
    let cookieData = getCookie(FIREBASE_COOKIE, context);
    if (typeof cookieData === 'string' || cookieData instanceof String) {
      // parse data from browser cookie
      cookieData = JSON.parse(cookieData || '{}');
    }
    if (cookieData && cookieData.hasOwnProperty(key)) {
      return cookieData[key];
    } else {
      return null;
    }
  } catch (error) {
    console.error(error, 'getFirebaseCookie');
  }
};

export const getNextLocaleCookie = () => {
  return Cookies.get(NEXT_LOCALE_COOKIE);
};

export const setNextLocaleCookie = locale => {
  Cookies.set(NEXT_LOCALE_COOKIE, locale, { expires: 7, sameSite: 'strict' });
};
