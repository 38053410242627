import styled from 'styled-components';
import colors from '../../theme/colors';

const SocialIconWrapper = styled('div')`
  position: relative;

  span {
    display: inline-block;
    color: ${colors.primary};
    text-decoration: none;
    transition: 0.15s ease-in-out;
    margin: 0 0.8em;

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const BorderCircle = styled('div')`
  max-width: 50px;
  max-height: 50px;
  border-radius: 50%;
  border: 1px solid #185069;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default SocialIconWrapper;
