import styled from 'styled-components';

export const NavbarWrapper = styled('header')`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  height: 76px;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: #ffffff;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.06);
  z-index: 999;
  padding-top: ${props => props.paddingY || 0};
  padding-bottom: ${props => props.paddingY || 0};
  padding-left: ${props => props.paddingX || '2rem'};
  padding-right: ${props => props.paddingX || '2rem'};
  .menu--msg-tag {
    background-color: #ed7877;
    color: white;
    text-align: center;
    border-radius: 50%;
    line-height: 20px;
    position: absolute;
    font-weight: bold;
    width: 20px;
    font-size: 12px;
    right: 30px;
    bottom: 4px;
  }
  @media (min-width: 769px) {
    .sm-hidden {
      display: none;
    }
  }

  @media (min-width: 992px) {
    .md-hidden {
      display: none;
    }
  }
  @media (max-width: 768px) {
    .md-show {
      display: none;
    }
    height: 56px;
    padding-left: ${props => props.paddingX || '1rem'};
    padding-right: ${props => props.paddingX || '0'};
  }

  @media (min-width: 1250px) {
    .logo-show-desktop {
      display: block;
    }
    .logo-hide-desktop {
      display: none;
    }
  }
  @media (max-width: 1250px) {
    .logo-hide-desktop {
      display: block;
    }
    .logo-show-desktop {
      display: none;
    }
  }
`;
