import { useTranslation } from 'next-i18next';
import React from 'react';
import Button from 'reusecore/src/elements/Button';
import { useAuth } from '../../contexts/AuthProvider';
import Logo from '../Logo';
import Search from '../Search';
import PublishBtn from '../Navbar/publishBtn';
import Menu from '../Menu';
import { NavbarWrapper } from './style';
const LogoImg = require('@core/ui/static/images/logo/yatoo.' +
  process.env.APP_LOCALIZATION +
  '.svg');
import LogoIcon from '@core/ui/static/images/icon.png?resize&sizes[]=50&sizes[]=100&sizes[]=200';
import LogoIconWebp from '@core/ui/static/images/icon.png?resize&sizes[]=50&sizes[]=100&sizes[]=200&format=webp';
import Flags from '../Locale';

const getImgLogo = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className={'logo-show-desktop'}>
        <Logo
          logoSrc={LogoImg}
          title={t(`${process.env.APP_LOCALIZATION}:country.brand`) + ' Logo'}
        />
      </div>
      <div className={'logo-hide-desktop'}>
        <Logo
          logoSrc={LogoIcon.src}
          logoSrcSet={LogoIcon.srcSet}
          webp={LogoIconWebp}
          sizes={'(min-width: 780px) 50px, 60px'}
          title={t(`${process.env.APP_LOCALIZATION}:country.brand`) + ' Logo'}
        />
      </div>
    </>
  );
};

const Navbar = ({
  onToggle,
  userId,
  buttonIcon,
  menuButtonIcon,
  dropdownMenuIcon,
  avatar,
  unreadMsg,
}) => {
  const { isLoggedIn } = useAuth();

  return (
    <NavbarWrapper>
      {getImgLogo()}

      <PublishBtn className="md-show" icon={menuButtonIcon} />

      <Search
        className="topSearch lg-show md-show"
        style={{ flexGrow: 1, marginLeft: 100, marginRight: 100 }}
      />

      {isLoggedIn && !!unreadMsg && unreadMsg > 0 && (
        <div className="menu--msg-tag sm-hidden">
          <span>{unreadMsg}</span>
        </div>
      )}
      <Menu
        className="md-show"
        userId={userId}
        buttonIcon={menuButtonIcon}
        dropdownMenuIcon={dropdownMenuIcon}
        avatar={avatar}
        unreadMsg={unreadMsg}
      />
      <div
        style={{ marginLeft: '1rem', display: 'flex', alignItems: 'center' }}
      >
        <Flags dropdown={true} />
        <Button
          icon={buttonIcon}
          onClick={onToggle}
          className="sm-hidden"
          variant="textButton"
          aria-label="Mon compte"
          style={{ marginRight: '0.3rem' }}
        />
      </div>
    </NavbarWrapper>
  );
};

export default Navbar;
