import React, { useEffect, useRef, useState } from 'react';
import InputSearch from '../InputSearch';
import SearchWrapper, { SearchBox } from './Search.style';
import Button from 'reusecore/src/elements/Button';
import searchIcon from '@core/ui/static/images/ico-search.svg';
import { MenuItemWrapper } from '../Menu/style';
import { useTranslation } from 'next-i18next';

const Search = ({ header = false }) => {
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState('');
  const [toggleSearch, setToggleSearch] = useState(false);

  let searchRef = useRef();
  let searchBoxRef = useRef();
  let searchDropDownRef = useRef();

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  const handleSearchInput = event => {
    setSearchValue(event.target.value);
  };

  const showSearch = () => {
    setToggleSearch(true);
    searchDropDownRef.current.input.current?.focus();
  };

  const handleClickOutside = event => {
    if (
      searchDropDownRef.current &&
      searchRef.current &&
      searchDropDownRef.current.dropdown &&
      !searchDropDownRef.current.prevDropdown &&
      !searchRef.current.contains(event.target)
    ) {
      // close dropdown
      searchDropDownRef.current.setDropdown(false);
      searchDropDownRef.current.prevDropdownRef = null;
    } else if (
      searchRef.current &&
      searchDropDownRef.current &&
      !searchDropDownRef.current.dropdown &&
      searchDropDownRef.current.prevDropdownRef === null &&
      !searchRef.current.contains(event.target)
    ) {
      // clicked outside
      setToggleSearch(false);
    } else {
      // reset
      searchDropDownRef.current.prevDropdownRef = null;
    }
  };

  return (
    <SearchWrapper ref={searchRef} className="md-show">
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          icon={
            <img
              src={searchIcon}
              style={{
                marginLeft: '15px',
              }}
              alt=""
            />
          }
          aria-label={t('header.search')}
          variant="outlined"
          color="#8C8C8C"
          onClick={showSearch}
          style={{
            marginLeft: 'auto',
            border: 'none',
          }}
        />
        <MenuItemWrapper style={{ marginLeft: -20 }}>
          <span className="button-search-header" onClick={showSearch}>
            {t('header.search')}
          </span>
        </MenuItemWrapper>
      </div>

      <SearchBox
        ref={searchBoxRef}
        className={toggleSearch ? 'expanded' : 'collapsed'}
      >
        <InputSearch
          ref={searchDropDownRef}
          type="text"
          value={searchValue}
          changed={handleSearchInput}
          searchText={searchValue}
          buttonIcon={
            <img
              src={searchIcon}
              style={{
                marginRight: '10px',
                marginLeft: '15px',
                verticalAlign: 'text-top',
                filter: 'invert(100%) sepia(100%)',
              }}
              alt=""
            />
          }
          placeholder={t('search.sidebar.keyword')}
          categoryStyle={{ marginLeft: 10, marginTop: 10 }}
          style={{ boxShadow: 'none' }}
          breakpoint={1236}
        />
      </SearchBox>
    </SearchWrapper>
  );
};
export default Search;
