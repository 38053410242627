import React from 'react';
import Link from 'next/link';
import SocialIcon from '../SocialIcon';
import FooterWrapper from './style';
import Icon from 'react-icons-kit';
import { CGU, ABOUT } from '@core/ui/navigation/constant';
import { facebook as socialFacebook } from 'react-icons-kit/feather/facebook';
import { useTranslation } from 'next-i18next';
import { Grid } from 'react-styled-flexboxgrid';
import { AppleIcon, AppStoreLink, GooglePlayIcon } from '../AppStoreBlock';
import colors from '../../theme/colors';

const Footer = props => {
  const { isMobile, className } = props;
  const { t } = useTranslation();

  const SOCIAL_ICONS = [
    {
      icon: (
        <Icon
          icon={socialFacebook}
          size={isMobile ? 24 : 18}
          color={colors.primary}
        />
      ),
    },
  ];

  return (
    <FooterWrapper className={className}>
      <Grid className="container">
        <div className="firstCol">
          <div className="app-logos">
            <AppStoreLink
              download={t(`${process.env.APP_LOCALIZATION}:store.download`)}
              store="App Store"
              href={process.env.LINK_IOS}
              icon={<AppleIcon height={50} width={50} />}
            />
            <AppStoreLink
              download={t(`${process.env.APP_LOCALIZATION}:store.download`)}
              store="Google Play"
              href={process.env.LINK_ANDROID}
              icon={<GooglePlayIcon height={50} width={50} />}
            />
          </div>
          <Link href={process.env.LINK_FACEBOOK}>
            <a className="social">
              {!isMobile ? (
                <span className="text">{t(`social.join`)}</span>
              ) : null}
              <SocialIcon
                className="social-icon"
                icons={SOCIAL_ICONS}
                isMobile={isMobile}
              />
            </a>
          </Link>
        </div>
        <div className="secondCol">
          <div className="footer-copyright">
            <Link href={`/${ABOUT}`}>
              <a>{t('footer.about')}</a>
            </Link>
            <Link href={`mailto:${process.env.MAIL_FROM_EMAIL}`}>
              <a> {t('footer.contact')}</a>
            </Link>
            <Link href={`/${CGU}`}>
              <a>{t('footer.cgu')}</a>
            </Link>
          </div>
        </div>
      </Grid>
    </FooterWrapper>
  );
};

export default Footer;
