import { getNextLocaleCookie } from './session';

const TRANSLATION = {
  fr: {
    'The email address is badly formatted.':
      "L'adresse e-mail est mal formatée.",
    'We have blocked all requests from this device due to unusual activity. Try again later.':
      "Nous avons bloqué toutes les demandes de cet appareil en raison d'activités inhabituelles. Réessayez plus tard.",
    'We have blocked all requests from this device due to unusual activity. Try again later':
      "Nous avons bloqué toutes les demandes de cet appareil en raison d'activités inhabituelles. Réessayez plus tard.",
    'The password is invalid or the user does not have a password.':
      'Le mot de passe est invalide.',
    'There is no user record corresponding to this identifier. The user may have been deleted.':
      "Il n'y a pas d'enregistrement utilisateur correspondant à cet identifiant. L'utilisateur a peut-être été supprimé.",
    'The password must be 6 characters long or more.':
      'Le mot de passe doit comporter 6 caractères ou plus.',
    'Password should be at least 6 characters':
      'Le mot de passe doit contenir au moins 6 caractères',
    'A network error (sush as timeout, interrupted connection or unreachable host) has occurred.':
      "Une erreur réseau (dépassement du délai, connexion interrompue ou hôte inaccessible) s'est produite.",
    'The email address is already in use by another account.':
      "L'adresse email est déjà utilisée par un autre compte.",
    'Too many unsuccessful login attempts. Please try again later':
      'Trop de tentative de connexion. Veuillez réessayer plus tard',
    brand: 'marque',
    model: 'modèle',
    year: 'années',
    energy: 'carburant',
    power: 'puissance',
    km: 'kilométrage',
    engine: 'cylindrée',
    gearcase: 'boîtes de vitesse',
    town: 'commune',
    type: 'type',
    pointure: 'pointure',
    size: 'taille',
    "Cannot read property 'email' of null":
      'Veuillez patienter pendant le chargement de la page',
    forgotErrorProvider:
      'Impossible de réinitialiser votre mot de passe car vous utilisez un service externe',
  },
  bi: {
    'The email address is badly formatted.':
      'The email address is badly formatted.',
    'We have blocked all requests from this device due to unusual activity. Try again later.':
      'We have blocked all requests from this device due to unusual activity. Try again later.',
    'We have blocked all requests from this device due to unusual activity. Try again later':
      'We have blocked all requests from this device due to unusual activity. Try again later',
    'The password is invalid or the user does not have a password.':
      'The password is invalid or the user does not have a password.',
    'There is no user record corresponding to this identifier. The user may have been deleted.':
      'There is no user record corresponding to this identifier. The user may have been deleted.',
    'The password must be 6 characters long or more.':
      'The password must be 6 characters long or more.',
    'Password should be at least 6 characters':
      'Password should be at least 6 characters',
    'A network error (sush as timeout, interrupted connection or unreachable host) has occurred.':
      'A network error (sush as timeout, interrupted connection or unreachable host) has occurred.',
    'The email address is already in use by another account.':
      'The email address is already in use by another account.',
    'Too many unsuccessful login attempts. Please try again later':
      'Tumas ansaksesful login atemp. Plis traem baken nex taem',
    brand: 'brand',
    model: 'model',
    year: 'year',
    energy: 'fuel',
    power: 'horse power',
    km: 'mileage',
    engine: 'capacity',
    gearcase: 'gearbox',
    town: 'town',
    type: 'type',
    pointure: 'shoes size',
    size: 'size',
    "Cannot read property 'email' of null":
      'Veuillez patienter pendant le chargement de la page',
    forgotErrorProvider:
      "Can't reset password because you use an external provider",
  },
  en: {
    'The email address is badly formatted.':
      'The email address is badly formatted.',
    'We have blocked all requests from this device due to unusual activity. Try again later.':
      'We have blocked all requests from this device due to unusual activity. Try again later.',
    'We have blocked all requests from this device due to unusual activity. Try again later':
      'We have blocked all requests from this device due to unusual activity. Try again later',
    'The password is invalid or the user does not have a password.':
      'The password is invalid or the user does not have a password.',
    'There is no user record corresponding to this identifier. The user may have been deleted.':
      'There is no user record corresponding to this identifier. The user may have been deleted.',
    'The password must be 6 characters long or more.':
      'The password must be 6 characters long or more.',
    'Password should be at least 6 characters':
      'Password should be at least 6 characters',
    'A network error (sush as timeout, interrupted connection or unreachable host) has occurred.':
      'A network error (sush as timeout, interrupted connection or unreachable host) has occurred.',
    'The email address is already in use by another account.':
      'The email address is already in use by another account.',
    brand: 'brand',
    model: 'model',
    year: 'year',
    energy: 'fuel',
    power: 'horse power',
    km: 'mileage',
    engine: 'capacity',
    gearcase: 'gearbox',
    town: 'town',
    type: 'type',
    pointure: 'shoes size',
    size: 'size',
    "Cannot read property 'email' of null":
      'Veuillez patienter pendant le chargement de la page',
    forgotErrorProvider:
      "Can't reset password because you use an external provider",
  },
};

const translate = key => {
  const currLang = getNextLocaleCookie();
  if (
    TRANSLATION.hasOwnProperty(currLang) &&
    TRANSLATION[currLang].hasOwnProperty(key)
  ) {
    return TRANSLATION[currLang][key];
  }
  return null;
};

export default translate;
