import styled from 'styled-components';
import bi from '@core/ui/static/images/locales/bi.svg';
import en from '@core/ui/static/images/locales/en.svg';
import fr from '@core/ui/static/images/locales/fr.svg';

export const Section = styled('span')`
  padding: 6px 10px;
`;

export const Flag = styled('span')`
  display: inline-block;
  width: 25px;
  height: 16px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  margin-left: 5px;
  margin-top: 1px;
  &.en {
    background-image: url(${en});
  }
  &.bi {
    background-image: url(${bi});
  }
  &.fr {
    background-image: url(${fr});
  }
`;
export const FlagLabel = styled('span')`
  margin-left: 5px;
  font-size: 18px;
  margin-right: 2px;
  padding: 0 2px;
  & + i {
    margin-top: 0 !important;
  }
`;

export const InlineFlags = styled('ul')`
  padding: 0;
  display: flex;
  justify-content: space-between;
  li {
    list-style: none;
    padding: 2px 5px 0 5px;
    margin: 0 2px;
    a {
      color: white;
    }
    &.active {
      border-bottom: 2px solid #00aec5;
      border-radius: 2px;
      padding-bottom: 5px;
    }
  }
`;
