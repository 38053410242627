import { firebaseAuth, db, analytics } from './firebaseHelper';
import { removeFirebaseCookie, removeCookie } from './session';
import * as Sentry from '@sentry/browser';
import firebase from 'firebase/app';
import Translate from '../helpers/translateHelper';

/**
 * Firebase Authentication helper functions
 */
class AuthHelper {
  constructor() {
    //firebaseAuth().languageCode = i18n.language || process.env.LOCALE;
  }

  setLang(lang) {
    firebaseAuth().languageCode = lang;
    return this;
  }

  signUp = async (provider, mail, password) => {
    try {
      switch (provider) {
        case 'password':
          return new Promise((resolve, reject) => {
            firebaseAuth()
              .createUserWithEmailAndPassword(mail, password)
              .then(result => {
                const { user } = result;
                user.sendEmailVerification();
                resolve(result);
              })
              .catch(error => {
                console.error('error signup => ', error);
                Sentry.captureEvent(error);
                resolve({ error });
              });
          });
        default:
          Sentry.captureMessage(`Provider non reconnu ${provider}`);
          break;
      }
    } catch (error) {
      console.error(error);
      Sentry.captureEvent(error);
    }
  };

  forgetPass = async mail => {
    return new Promise((resolve, reject) => {
      // get provider
      firebaseAuth()
        .fetchSignInMethodsForEmail(mail)
        .then(signInMethods => {
          if (
            signInMethods.length === 0 ||
            signInMethods.indexOf(
              firebaseAuth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD
            ) !== -1
          ) {
            firebaseAuth()
              .sendPasswordResetEmail(mail)
              .then(result => {
                resolve(true);
              })
              .catch(error => {
                Sentry.captureEvent(error);
                resolve({ error });
              });
          } else {
            const error = {
              message:
                Translate('forgotErrorProvider') + ': ' + signInMethods[0],
            };
            resolve({ error });
          }
        })
        .catch(error => {
          Sentry.captureEvent(error);
          resolve({ error });
        });
    });
  };

  reauthenticate = async currentPassword => {
    var user = firebaseAuth().currentUser;
    var cred = firebaseAuth.EmailAuthProvider.credential(
      user.email,
      currentPassword
    );
    return user.reauthenticateWithCredential(cred);
  };

  changePassword = async (currentPassword, newPassword) =>
    new Promise((resolve, reject) => {
      try {
        this.reauthenticate(currentPassword)
          .then(() => {
            var user = firebaseAuth().currentUser;
            user
              .updatePassword(newPassword)
              .then(() => {
                resolve(true);
              })
              .catch(error => {
                resolve({ error });
              });
          })
          .catch(error => {
            resolve({ error });
          });
      } catch (error) {
        Sentry.captureEvent(error);
        resolve({ error });
      }
    });

  login = async (
    provider,
    mail = null,
    password = null,
    phoneNumber = null
  ) => {
    try {
      // check browser cookie enabled and set persistence
      if (navigator.cookieEnabled) {
        await firebaseAuth().setPersistence(
          firebaseAuth.Auth.Persistence.LOCAL
        );
      }

      switch (provider) {
        case 'password':
          return new Promise((resolve, reject) => {
            firebaseAuth()
              .signInWithEmailAndPassword(mail, password)
              .then(result => {
                resolve(result);
              })
              .catch(error => {
                resolve({ error });
              });
          });
        case 'twitter':
          var authProvider = new firebaseAuth.TwitterAuthProvider();
          return firebaseAuth().signInWithPopup(authProvider);
        case 'phone':
          let appVerifier;
          appVerifier = window.recaptchaVerifier;
          return new Promise((resolve, reject) => {
            firebaseAuth()
              .signInWithPhoneNumber(phoneNumber, appVerifier)
              .then(result => {
                resolve(result);
              })
              .catch(error => {
                resolve({ error });
              });
          });
      }
    } catch (error) {
      Sentry.captureEvent(error);
      return { error };
    }
  };

  loginProvider = async (
    provider,
    email,
    firstname,
    lastname,
    locale,
    token,
    accessToken,
    nonce,
    t
  ) => {
    let credential;
    let providerName = '';

    // set persistence
    await firebaseAuth().setPersistence(firebaseAuth.Auth.Persistence.LOCAL);

    switch (provider) {
      case 'apple':
        providerName = 'apple.com';
        const appleProvider = new firebaseAuth.OAuthProvider(providerName);
        credential = appleProvider.credential({
          idToken: token,
          rawNonce: nonce,
        });
        break;
      case 'facebook':
        credential = firebaseAuth.FacebookAuthProvider.credential(token);
        providerName = 'facebook.com';
        break;
      case 'google':
        credential = firebaseAuth.GoogleAuthProvider.credential(
          token,
          accessToken
        );
        providerName = 'google.com';
        break;
    }

    let userRegistered = true;

    // check if user exists and if not register him
    if (email !== null && email !== '') {
      const registerUser = await db
        .collection('users')
        .where('mail', '==', email)
        .limit(1)
        .get()
        .then(async querySnapshot => {
          if (querySnapshot.size === 0) {
            console.log('need to register user');
            userRegistered = false;

            ///// check spam
            const isSpam = await fetch(process.env.FUNCTION_LOCATE, {
              mode: 'cors',
            })
              .then(response => response.json())
              .then(json => {
                if (json.result && json.result === 'success') {
                  const { location } = json.data;
                  const authorizedCountry = ['NC', 'PF', 'VU', 'AU', 'FJ'];
                  return !(
                    location.country &&
                    authorizedCountry.includes(location.country)
                  );
                }
                // if there is no result or success result, return true
                return true;
              })
              .catch(error => {
                console.error('error in getting function locate : ', error);
                return true;
              });

            const dateSend = firebase.firestore.FieldValue.serverTimestamp();

            // check if firstname and lastname is not undefined
            firstname = firstname ?? 'John';
            lastname = lastname ?? 'Doe';

            const displayName = firstname;

            const register = await db
              .collection('usersInfo')
              .doc(email)
              .set(
                {
                  prenom: firstname,
                  name: lastname,
                  alternateName: displayName,
                  mail: email,
                  createdAt: dateSend,
                  updatedAt: dateSend,
                  lang: locale,
                  spam: isSpam,
                  provider: providerName,
                  created_from: 'web',
                },
                { merge: true }
              )
              .then(() => {
                return true;
              })
              .catch(error => {
                console.error('error in user registration => ', error);
                return false;
              });

            return { success: register, isSpam };
          }
          return { success: true };
        })
        .catch(error => {
          console.error('check user error => ', error);
          Sentry.captureEvent(error);
          return {
            success: false,
            error: { message: 'Error during user registration' },
          };
        });

      const { success } = registerUser;
      if (success === false) {
        return registerUser;
      }
    } else {
      Sentry.captureEvent('Email empty');
      return {
        success: false,
        error: { message: t('signin.error.providerPermission') },
      };
    }

    // handle providers
    return firebaseAuth()
      .signInWithCredential(credential)
      .then(response => {
        const { user: firebaseUser } = response;

        if (userRegistered === false) {
          // add analytics signup event
          if (typeof window !== 'undefined' && analytics) {
            analytics.logEvent('sign_up', { method: provider });
          }
        }

        // add analytics login event
        if (typeof window !== 'undefined' && analytics) {
          analytics.logEvent('login', { method: provider });
        }

        return { success: true };
      })
      .catch(async error => {
        console.error('Error => ', error);
        await this.logout();
        return { success: false, error };
      });
  };

  confirmVerification = async (confirmationResult, code) => {
    return new Promise((resolve, reject) => {
      confirmationResult
        .confirm(code)
        .then(result => {
          resolve(result.user);
        })
        .catch(function(error) {
          resolve({ error });
        });
    });
  };

  getToken = async User => {
    try {
      return User.getIdToken();
    } catch (error) {
      Sentry.captureEvent(error);
    }
  };

  /*refreshToken = async () => {
    const { currentUser } = firebaseAuth();
    if (!currentUser) {
      // may be not adapted ?
      // Router.push(`/signin?redirect=${window.location.pathname}`);
      return;
    }
    await currentUser
      .getIdToken(true)
      .then(function(idToken) {
        setCookie('id_token', idToken);
      })
      .catch(function(error) {});
  };*/

  logout = async () => {
    return new Promise((resolve, reject) => {
      try {
        firebaseAuth()
          .signOut()
          .then(() => {
            removeCookie('id_token');
            removeFirebaseCookie('user');

            // set analytics user id to null
            if (typeof window !== 'undefined' && analytics) {
              analytics.setUserId(null);
            }

            resolve(true);
          });
      } catch (error) {
        reject(false);
      }
    });
  };

  isAuthenticated = async () => {
    return new Promise((resolve, reject) => {
      const unsubscribe = firebaseAuth().onAuthStateChanged(
        authUser => {
          resolve(authUser);
          unsubscribe();
        },

        error => reject(error)
      );
    });
  };

  getCurrentUser = () => {
    return firebaseAuth().currentUser;
  };

  getUserData = async (id) => {
    try {
      const userDoc = await db.collection('users').doc(id).get();
      if (userDoc.exists) {
        return {id, ...userDoc.data()};
      }
      return null;
    } catch (e) {
      console.error(e);
      return null;
    }
  }
}

export default new AuthHelper();
