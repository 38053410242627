import { useRouter } from 'next/router';
import { createContext, useContext, useEffect, useState } from 'react';
import { analytics, firebaseAuth } from '../helpers/firebaseHelper';
import authHelper from '../helpers/authHelper';
import { setCookie } from '../helpers/session';

const AuthContext = createContext({
  isLoggedIn: false,
  login: async () => null,
  logout: async () => null,
  token: null,
  user: null,
});

const AuthProvider = props => {
  const { children } = props;

  const router = useRouter();

  const [state, setState] = useState({ token: null, user: null });

  const login = async (provider, mail, password, phoneNumber) => {
    return authHelper.login(provider, mail, password, phoneNumber);
  };

  const logout = async (redirect = true) => {
    return authHelper.logout().then(() => {
      // redirect to /
      if (redirect === true) {
        router.push('/');
      }
    });
  };

  const token = state.token;

  const user = state.user;

  const isLoggedIn = !!user;

  let handle;

  const clearSetInterval = () => {
    if (handle) {
      clearInterval(handle);
      handle = null;
    }
  };

  useEffect(() => {
    // listen onAuthStateChanged
    return firebaseAuth().onAuthStateChanged(async firebaseUser => {
      let newToken = null;
      let newUser = null;

      const { emailVerified = false, providerData = [] } = firebaseUser || {};

      let checkProviderEmailVerified = emailVerified;

      // ignore emailVerified if provider is different of "password"
      if (
        providerData.findIndex(element => element.providerId !== 'password') !==
        -1
      ) {
        checkProviderEmailVerified = true;
      }

      if (firebaseUser && checkProviderEmailVerified) {
        // set analytics user id
        if (typeof window !== 'undefined' && analytics) {
          analytics.setUserId(firebaseUser.uid);
        }
        newUser = firebaseUser;
        newToken = await firebaseUser.getIdToken();
        await setCookie('id_token', newToken);
      } else {
        if (isLoggedIn) {
          await logout(false);
        }
      }

      setState({ token: newToken, user: newUser });
    });
  }, []);

  useEffect(() => {
    clearSetInterval();
    const currentUser = firebaseAuth().currentUser;
    if (currentUser) {
      // ask new token periodically
      handle = setInterval(() => {
        // TODO maybe check token (JWT) validity before forcing new token
        // force new token
        currentUser
          .getIdToken(true)
          .then(async idToken => {
            // set the new token
            await setCookie('id_token', idToken);
            setState({ ...state, token: idToken });
          })
          .catch(error => {
            console.warn('Error getting new token => ', error);
          });
      }, 10 * 60 * 1000);
    }

    return () => clearSetInterval();
  }, [state]);

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout, token, user }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthProvider;
