const colors = {
  transparent: 'transparent', // 0
  black: '#000000', // 1
  white: '#ffffff', // 2
  textColor: '#484848', // 3
  labelColor: '#767676', // 4
  inactiveField: '#f2f2f2', // 5
  inactiveButton: '#b7dbdd', // 6
  inactiveIcon: '#EBEBEB', // 7
  primary: '#00BCD4', // 8
  primaryHover: '#002F3F', // 9
  secondary: '#ff5b60', // 10
  secondaryHover: '#FF282F', // 11
  yellow: '#fdb32a', // 12
  yellowHover: '#F29E02', // 13
  fontColor: '#333333',
  blue: '#0B2F3F',
  blueDark: '#041C26',
  blueLight: '#174459',
  blueLight2: '#113A4D',
};

export default colors;
