import styled from 'styled-components';
export const SpanAvatarInitial = styled.span`
  display: flex;
  align-items: center;
  font-size: 20px;
  color: #ffffff;
  margin: auto;
`;

export const AvatarInitialWrapper = styled.div`
  width: ${props => (props.imgWidth ? props.imgWidth + 'px' : '100%')};
  height: ${props => (props.imgHeight ? props.imgHeight + 'px' : '100%')};
  display: flex;
  background-color: #0a2e3e;
  justify-content: center;
  flex-shrink: 0;
  overflow: hidden;
  position: relative;
  border-radius: ${props => (props.imgRadius ? props.imgRadius : 100)}px;
  max-width: 100%;
  @media only screen and (max-width: 767px) {
    width: ${props => (props.imgWidth ? props.imgWidth / 1.2 + 'px' : '100%')};
    height: ${props =>
      props.imgHeight ? props.imgHeight / 1.2 + 'px' : '100%'};
  }
`;
