import styled from 'styled-components';

export const DropdownMenuWrapper = styled('div')`
  position: relative;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  i {
    transition: 0.3s ease-in-out;
  }
  &:hover {
    i {
      color: #00bcd4;
    }
  }
`;

export const DropdownMenuItemsWrapper = styled('ul')`
  margin-top: 50px;
  padding: 10px 20px;
  list-style: none;
  background-color: #ffffff;
  position: absolute;
  top: 0;
  left: ${props => (props.dropdownDirection === 'left' ? '0' : 'auto')};
  right: ${props => (props.dropdownDirection === 'right' ? '0' : 'auto')};
  z-index: 15;
  min-width: 190px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.16);
  border-radius: 3px;
  &.flag {
    min-width: 70px;
    padding: 10px;
    li {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
      & > span {
        display: flex;
        justify-content: left;
        align-items: center;
      }
    }
  }
`;

export const DropdownMenuItemWrapper = styled('li')`
  transition: background-color 0.3s ease-in-out;
  a {
    display: block !important;
    padding: 10px 0 !important;
    color: #123545;
    font-size: 15px;
  }
  button {
    display: inline;
    min-height: auto;
    min-width: auto;
    text-decoration: none;
    padding: 10px 0;
    span.btn-text {
      padding: 0;
      font-size: 15px;
    }
    &:hover {
      span {
        color: #00bcd4;
      }
    }
  }
  //&:hover {
  //  background-color: #e2e2e2;
  //}
`;
