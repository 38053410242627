import { Flag, FlagLabel, Section } from './style';
import DropdownMenu from '../DropdownMenu';
import React from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

const Dropdown = ({ onChange, ...props }) => {
  const { locale, locales } = useRouter();

  const { t } = useTranslation();

  return (
    <DropdownMenu
      className="flag"
      dropdownDirection="right"
      style={{ marginRight: 10 }}
      content={
        <>
          <Flag className={locale} />
          <FlagLabel className="label">{t(locale)}</FlagLabel>
        </>
      }
      dropdownItems={locales.map(lng => (
        <Section onClick={() => onChange(lng)}>
          <Flag className={lng} key={lng} />
          <FlagLabel className="label">{t('lang.' + lng)}</FlagLabel>
        </Section>
      ))}
    />
  );
};

export default Dropdown;
