import React from 'react';
import Link from 'next/link';
import { MenuItemWrapper } from '../Menu/style';
import Button from 'reusecore/src/elements/Button';
import { ADD_POST } from '@core/ui/navigation/constant';
import { useTranslation } from 'next-i18next';

const PublishBtn = ({ icon, ...props }) => {
  const { t } = useTranslation();

  return (
    <MenuItemWrapper {...props}>
      <Link href={`/${ADD_POST}/new`} passHref>
        <Button
          title={t('header.publish')}
          icon={icon}
          iconPosition="left"
          bg="transparent"
          color="#00BCD4"
          border="1px solid #00BCD4"
          className={'secondary'}
        />
      </Link>
    </MenuItemWrapper>
  );
};

export default PublishBtn;
