import { useTranslation } from 'next-i18next';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useRouter } from 'next/router';
import Icon from 'react-icons-kit';
import styled from 'styled-components';
import useMediaQuery from '../../helpers/mediaQuery';
import { slugify } from '../../helpers/urlHandler';
import ListCategories from '../../models/local/categories/ListCategories';
import { SearchInputWrapper, SearchInput, SearchButton } from './style';
import { SEARCH_PAGE } from '@core/ui/navigation/constant';
import { iosArrowDown } from 'react-icons-kit/ionicons/iosArrowDown';
import { iosArrowUp } from 'react-icons-kit/ionicons/iosArrowUp';
import { iosArrowRight } from 'react-icons-kit/ionicons/iosArrowRight';
import { bars } from 'react-icons-kit/fa/bars';

const ButtonCategories = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 20px;
  margin-left: 10px;
  padding-right: 10px;
  border-right: 1px solid rgba(89, 89, 89, 0.15);
  font-family: Poppins;

  &.isMobile {
    border: none;
  }
`;

const MobileSearchWrapper = styled.div`
  display: flex;

  .category {
    display: flex;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 10px;
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  }

  // @media only screen and (max-width: 1023px) {
  //   margin-left: 10px;
  //   margin-top: 10px;
  // }
`;

const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  background-color: #ffffff;
  position: absolute;
  z-index: 2;
  margin-top: 5px;
  padding: 10px 20px;
  box-shadow: 0 14px 25px 0 rgba(0, 0, 0, 0.15);
  gap: 20px;
  font-family: Poppins;
`;

const Ul = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 10px;

  li {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 14px;
    margin: 10px 0;

    .label {
      display: flex;
      align-items: center;
      gap: 14px;
      flex-grow: 1;
    }

    .next {
      opacity: 0;
    }

    &.active,
    &:hover {
      color: #03b2cb;

      .next {
        opacity: 1;
      }
    }
  }
`;

const getCategories = t => {
  let categories = ListCategories(t);
  if (categories[0].id !== t('search.sidebar.all')) {
    categories.unshift({
      options: [
        { value: t('search.sidebar.all'), label: t('search.sidebar.all') },
      ],
      id: t('search.sidebar.all'),
      icon: bars,
      label: t('search.sidebar.all'),
    });
  }

  return categories;
};

const DropDown = forwardRef(({ category, close, onSelect, parentRef }, ref) => {
  const { t } = useTranslation();

  //const wrapperRef = useRef(ref);

  const [cat, setCat] = useState(category.mainCategory);
  const [sub, setSub] = useState(category.subCategory);

  const mainCategorySelected = mainCategory => () => {
    setCat(mainCategory);
  };

  const selectFirstCategory = mainCategory => () => {
    const { options } = mainCategory;
    setCat(mainCategory);
    setSub(options[0]);
    onSelect(cat, options[0]);
  };

  const categorySelected = subCategory => () => {
    setSub(subCategory);
    onSelect(cat, subCategory);
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        !ref.current.contains(event.target)
      ) {
        // close dropdown
        close();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <DropdownWrapper ref={ref}>
      <Ul>
        {getCategories(t).map(category => (
          <li
            key={category.id}
            onMouseOver={mainCategorySelected(category)}
            onClick={selectFirstCategory(category)}
            className={cat && cat.id === category.id ? 'active' : ''}
          >
            <div className="label">
              <Icon icon={category.icon} />
              <span>{category.label}</span>
            </div>
            <Icon
              icon={iosArrowRight}
              size={18}
              color="#BABABA"
              className="next"
            />
          </li>
        ))}
      </Ul>
      {cat && (
        <Ul
          style={{
            borderLeft: '1px solid rgba(89,89,89,0.15)',
            paddingLeft: 20,
          }}
        >
          {cat.options.map(s => (
            <li
              key={s.value}
              onClick={categorySelected(s)}
              className={sub && sub.value === s.value ? 'active' : ''}
            >
              <span>{s.label}</span>
            </li>
          ))}
        </Ul>
      )}
    </DropdownWrapper>
  );
});

DropDown.displayName = 'DropDown';

const InputSearch = forwardRef(
  (
    {
      type,
      value,
      buttonText,
      buttonIcon,
      placeholder,
      changed,
      style,
      onKeyPress,
      onBlur,
      searchStyle,
      categoryStyle,
      breakpoint = 1023,
    },
    ref
  ) => {
    const router = useRouter();
    const { t } = useTranslation();

    const isMobile = useMediaQuery('(max-width: ' + breakpoint + 'px)');

    const input = useRef();
    const buttonRef = useRef();
    const dropdownRef = useRef();

    const [dropdown, setDropdown] = useState(false);
    const [category, setCategory] = useState({});

    const prevDropdown = useRef(false);
    const prevDropdownRef = useRef(null);

    const handleSearch = e => {
      e.preventDefault();
      const { subCategory = {} } = category;
      let categorySelected = slugify(subCategory.value ?? 'all');

      let searchUrl = `/${SEARCH_PAGE}/${categorySelected}`;
      if (value.trim()) {
        searchUrl += `?text=${value.trim()}`;
      }
      router.push(searchUrl);
    };

    const toggleDropdown = event => {
      event.preventDefault();
      if (dropdown) prevDropdownRef.current = null;
      prevDropdown.current = dropdown;
      setDropdown(!dropdown);
    };

    const onSelect = (mainCategory, subCategory) => {
      setCategory({ mainCategory, subCategory });
      prevDropdown.current = true;
      prevDropdownRef.current = dropdownRef.current;
      setDropdown(false);
      input.current.focus();
    };

    const getCategoriesButton = () => {
      const { subCategory: { label } = {} } = category;
      return (
        <ButtonCategories
          ref={buttonRef}
          onClick={toggleDropdown}
          className={isMobile ? 'isMobile' : ''}
        >
          <span>{label ?? t('footer.categories')}</span>
          <Icon
            icon={dropdown ? iosArrowUp : iosArrowDown}
            size={18}
            color="#BABABA"
          />
        </ButtonCategories>
      );
    };

    useImperativeHandle(
      ref,
      () => ({
        dropdown,
        input,
        prevDropdown: prevDropdown.current,
        prevDropdownRef: prevDropdownRef.current,
        setDropdown,
      }),
      [dropdown, setDropdown]
    );

    return (
      <>
        {isMobile && (
          <MobileSearchWrapper>
            <div className="category" style={categoryStyle}>
              {getCategoriesButton()}
            </div>
          </MobileSearchWrapper>
        )}
        <SearchInputWrapper onSubmit={handleSearch} style={style}>
          {!isMobile && getCategoriesButton()}

          <SearchInput
            ref={input}
            type={type}
            value={value}
            placeholder={placeholder}
            onChange={changed}
            onKeyPress={onKeyPress}
            onBlur={onBlur}
            aria-label={t('home.search.button')}
            style={searchStyle}
          />
          <SearchButton type="submit" aria-label="Recherche">
            {buttonIcon}{' '}
            <span className="inputsearch-btn--text">{buttonText}</span>
          </SearchButton>
        </SearchInputWrapper>
        {dropdown && (
          <DropDown
            ref={dropdownRef}
            onSelect={onSelect}
            category={category}
            close={() => setDropdown(false)}
            parentRef={buttonRef}
          />
        )}
      </>
    );
  }
);

export default InputSearch;

InputSearch.displayName = 'InputSearch';
