import React, { useState, useEffect } from 'react';
import Icon from 'react-icons-kit';
import { iosArrowDown } from 'react-icons-kit/ionicons/iosArrowDown';
import { iosArrowUp } from 'react-icons-kit/ionicons/iosArrowUp';
import {
  DropdownMenuWrapper,
  DropdownMenuItemsWrapper,
  DropdownMenuItemWrapper,
} from './style';

const DropdownMenu = props => {
  const [menuState, setMenuState] = useState({
    show: false,
  });

  useEffect(() => {
    window.addEventListener('click', handleDocumentClick);
    return () => {
      window.removeEventListener('click', handleDocumentClick);
    };
  });

  const handleToggle = () => {
    setMenuState(prevState => ({
      ...menuState,
      show: !prevState.show,
    }));
  };
  const handleDocumentClick = () => {
    if (menuState.show) {
      handleToggle();
    }
  };

  const { content, dropdownItems, dropdownDirection, className } = props;
  return (
    <DropdownMenuWrapper onClick={e => e.stopPropagation()}>
      <span
        style={{ display: 'flex', alignItems: 'center' }}
        onClick={handleToggle}
      >
        {content}{' '}
        <Icon
          icon={menuState.show ? iosArrowUp : iosArrowDown}
          size={18}
          color="#BABABA"
        />
      </span>
      {menuState.show && (
        <DropdownMenuItemsWrapper
          className={className}
          dropdownDirection={dropdownDirection}
        >
          {dropdownItems &&
            dropdownItems.map((item, index) => (
              <DropdownMenuItemWrapper key={index} onClick={handleToggle}>
                {item}
              </DropdownMenuItemWrapper>
            ))}
        </DropdownMenuItemsWrapper>
      )}
    </DropdownMenuWrapper>
  );
};

export default DropdownMenu;
