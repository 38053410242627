import React, { useState } from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import { naviconRound } from 'react-icons-kit/ionicons/naviconRound';
import { plusRound } from 'react-icons-kit/ionicons/plusRound';
import { more } from 'react-icons-kit/ionicons/more';
import { FullPageSpinner } from '../../components/Loader/spinnerLoader';
import { useAuth } from '../../contexts/AuthProvider';
import { androidClose } from 'react-icons-kit/ionicons/androidClose';
import Icon from 'react-icons-kit';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import SideDrawer from 'reusecore/src/elements/Drawer';
import { useUser } from '../../contexts/UserContext';
import LazyLoad from 'react-lazyload';
import profileImg from '@core/ui/static/images/user-placeholder.svg';
import AvatarInitials from '../../components/Avatar';
import {
  IoIosCall,
  IoMdMail,
  IoMdHome,
  IoIosChatbubbles,
  IoIosPaper,
  IoIosSearch,
  IoMdAddCircleOutline,
  IoIosPerson,
  IoMdHeartEmpty,
} from 'react-icons/io';
import {
  HOME_PAGE,
  SEARCH_PAGE,
  ACCOUNT_PAGE,
  SIGNIN_PAGE,
  ADD_POST,
  MESSAGERIE,
  ALERTS,
  FAVOURITE_PAGE,
  ACCOUNT_SETTING_PAGE,
} from '@core/ui/navigation/constant';
import { bell } from 'react-icons-kit/typicons/bell';
import { useTranslation } from 'next-i18next';
import { AVATAR_MEDIUM, AVATAR_SMALL, getPhoto } from '../../helpers/getPhotos';
import useMediaQuery from '../../helpers/mediaQuery';

const Main = styled.main`
  min-height: 100vh;
  min-height: calc(100vh - 258px);
`;

const DrawerWrapper = styled('div')`
  display: inline-flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
  padding: 70px 20px 50px;
  background-color: #002f3f;
`;

const DrawerLink = styled('span')`
  display: inline-flex;
  padding: 5px 0;

  a {
    color: white;
    opacity: 0.9;
    width: 100%;
    line-height: 50px;
    &:hover {
      color: #00bcd4;
      .menu--msg-tag {
        span {
          color: white;
        }
      }
    }
  }

  svg {
    margin-right: 10px;
    font-size: 21px;
    position: relative;
    top: 4px;
  }

  .menu--msg-tag {
    span {
      background: #00bcd4;
      padding: 3px 6px;
      border-radius: 10px;
    }
  }
`;

const MenuItemAvatarWrapper = styled('div')`
  display: flex;
  color: white;
  border-bottom: 1px solid #ffffff38;
  padding-bottom: 30px;
`;

const MenuItemUserInfosWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Layout = props => {
  const { children, userId, history, pathname } = props;

  const [drawerState, toggleDrawer] = useState(false);
  const [loggingOut, setloggingOut] = useState(false);

  const { t } = useTranslation();
  const { isLoggedIn, logout } = useAuth();

  const classNameFooter = pathname ? pathname.split('/')[1] : '';
  const { state } = useUser();

  const { avatar, image, unreadMsg } = state;

  const isMobile = useMediaQuery('(max-width: 1023px)');

  const authorImg = (size = AVATAR_SMALL) => {
    if (avatar) {
      if (image) {
        return getPhoto(image, size);
      }

      return { contentUrl: avatar };
    }

    return null;
  };

  const handleLogout = async () => {
    if (logout instanceof Function) {
      logout();
    }
  };

  const avatarStyle = {
    width: 68,
    height: 68,
    borderRadius: '50%',
    objectFit: 'cover',
    marginRight: 10,
  };

  const handleToggle = () => toggleDrawer(!drawerState);

  const DRAWER_MENU = [
    {
      path: `${HOME_PAGE}`,
      name: t('Home'),
      svg: <IoMdHome color="#00BCD4" title="" />,
    },
    {
      path: `${SEARCH_PAGE}/all`,
      name: t('header.search'),
      svg: <IoIosSearch color="#00BCD4" title="" />,
    },
    {
      path: `${ADD_POST}/new`,
      name: t('header.publish'),
      svg: <IoMdAddCircleOutline color="#00BCD4" title="" />,
    },
    {
      path: `${MESSAGERIE}`,
      name: t('header.chat.label'),
      svg: <IoIosChatbubbles color="#00BCD4" title="" />,
    },
    {
      path: `${ACCOUNT_PAGE}/myClassifieds`,
      name: t('myAds'),
      svg: <IoIosPaper color="#00BCD4" title="" />,
    },
    {
      path: `${ACCOUNT_PAGE}/${FAVOURITE_PAGE}`,
      name: t('header.favorite.label'),
      svg: <IoMdHeartEmpty color="#00BCD4" title="" />,
    },
    {
      path: `${ACCOUNT_PAGE}/${ALERTS}`,
      name: t('header.alerts.label'),
      svg: (
        <Icon
          icon={bell}
          size={24}
          style={{ color: '#00BCD4', position: 'relative', top: -5 }}
        />
      ),
    },
  ];

  let InitialProfil;
  if (!authorImg()) {
    InitialProfil = (
      <AvatarInitials
        customStyle={{
          height: 112,
          width: 112,
        }}
        customSpanStyle={{
          fontSize: 30,
        }}
      />
    );
  } else {
    const {
      contentUrl,
      height = null,
      source = null,
      thumbnail,
      width = null,
    } = authorImg(AVATAR_MEDIUM) || {};
    const {
      contentUrl: thumbnailContentUrl,
      height: thumbnailHeight = null,
      source: thumbnailSource = null,
      width: thumbnailWidth = null,
    } = thumbnail || {};

    const imgSrc = thumbnail ? thumbnailContentUrl : contentUrl;
    const imgHeight = thumbnail ? thumbnailHeight : height;
    const imgSource = thumbnail ? thumbnailSource : source;
    const imgWidth = thumbnail ? thumbnailWidth : width;

    InitialProfil = (
      <LazyLoad
        placeholder={<img style={avatarStyle} src={profileImg} alt="Avatar" />}
        offset={300}
        once
        style={avatarStyle}
      >
        <picture>
          {imgSource}
          <img
            src={imgSrc}
            alt="profile picture"
            style={avatarStyle}
            height={imgHeight}
            width={imgWidth}
          />
        </picture>
      </LazyLoad>
    );
  }

  return (
    <>
      {loggingOut && <FullPageSpinner />}
      <Navbar
        onToggle={handleToggle}
        userId={userId}
        history={history}
        buttonIcon={<Icon icon={naviconRound} size={35} color="#ffffff" />}
        menuButtonIcon={<Icon icon={plusRound} size={20} color="#595959" />}
        dropdownMenuIcon={<Icon icon={more} size={40} color="#595959" />}
        pathname={pathname}
        avatar={authorImg()}
        unreadMsg={unreadMsg}
      />
      {drawerState && (
        <SideDrawer
          open={drawerState}
          onClose={handleToggle}
          toggleHandler={handleToggle}
          closeButton={
            <Icon icon={androidClose} size={21} style={{ color: 'white' }} />
          }
          closeButtonStyle={{
            display: 'inline-flex',
            position: 'absolute',
            top: 20,
            right: 20,
            cursor: 'pointer',
          }}
        >
          <DrawerWrapper>
            {isLoggedIn && (
              <MenuItemAvatarWrapper>
                {InitialProfil}
                <MenuItemUserInfosWrapper>
                  <span
                    style={{ textransform: 'capitalize', fontSize: '18px' }}
                  >
                    {state.userName}
                  </span>
                  {state.mail && (
                    <div
                      style={{
                        fontSize: 14,
                        marginTop: '7px',
                        opacity: '0.8',
                      }}
                    >
                      <span style={{ display: 'flex' }}>
                        <IoMdMail size={15} />
                        <span style={{ lineHeight: '14px', marginLeft: '5px' }}>
                          {' '}
                          {state.mail}
                        </span>
                      </span>
                    </div>
                  )}
                  {state.mobile && (
                    <div
                      style={{
                        fontSize: 13,
                        marginTop: '7px',
                        marginLeft: '1px',
                        opacity: '0.8',
                      }}
                    >
                      <span style={{ display: 'flex' }}>
                        <IoIosCall size={15} />
                        <span style={{ lineHeight: '15px', marginLeft: '3px' }}>
                          {' '}
                          {state.mobile}
                        </span>
                      </span>
                    </div>
                  )}
                </MenuItemUserInfosWrapper>
              </MenuItemAvatarWrapper>
            )}
            {DRAWER_MENU.map((item, index) => (
              <DrawerLink key={index}>
                <Link href={`/${item.path}`}>
                  <a
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div>
                      {item.svg}
                      {item.name}
                    </div>
                    {item.name === t('header.chat.label') &&
                      isLoggedIn &&
                      !!state.unreadMsg &&
                      state.unreadMsg > 0 && (
                        <div className="menu--msg-tag">
                          <span>{state.unreadMsg}</span>
                        </div>
                      )}
                  </a>
                </Link>
              </DrawerLink>
            ))}

            {isLoggedIn ? (
              <>
                <DrawerLink key={ACCOUNT_SETTING_PAGE}>
                  <Link href={`/${ACCOUNT_SETTING_PAGE}`}>
                    <a>
                      <IoIosPerson color="#00BCD4" />
                      {t('header.profile')}
                    </a>
                  </Link>
                </DrawerLink>
                <span
                  onClick={handleLogout}
                  title={t('logout')}
                  width={1}
                  color="#EF5A5A"
                  style={{
                    minWidth: 'auto',
                    textDecoration: 'none',
                    marginTop: 30,
                    fontSize: '14px',
                    color: '#EF5A5A',
                    cursor: 'pointer',
                    margin: 'auto',
                  }}
                >
                  {t('logout')}
                </span>
              </>
            ) : (
              <Link href={`/${SIGNIN_PAGE}`}>
                <a
                  style={{
                    minWidth: 'auto',
                    textDecoration: 'none',
                    marginTop: 30,
                    fontSize: '18px',
                    color: '#00BCD4',
                    cursor: 'pointer',
                    textAlign: 'center',
                    border: '1px solid #00BCD4',
                    padding: '15px',
                  }}
                >
                  {t('login')}
                </a>
              </Link>
            )}
          </DrawerWrapper>
        </SideDrawer>
      )}
      <Main>{children}</Main>
      <Footer isMobile={isMobile} className={classNameFooter} />
    </>
  );
};

export default Layout;
