import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';
import 'firebase/analytics';
import config from './firebase.config';

let analytics;

// https://github.com/zeit/next.js/issues/1999#issuecomment-302244429
// For stopping reinitialization
if (!firebase.apps.length) {
  firebase.initializeApp(config);
  if (typeof window !== 'undefined') {
    // check analytics supported
    firebase.analytics.isSupported().then(isSupported => {
      if (isSupported) {
        analytics = firebase.analytics();
      }
    });
  }
}

const db = firebase.firestore();
const storage = firebase.storage();
const firebaseAuth = firebase.auth;
export { analytics, db, storage, firebaseAuth };
