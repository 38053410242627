import styled from 'styled-components';
import PropTypes from 'prop-types';
import Card from 'reusecore/src/elements/Card';
import { useTranslation } from 'next-i18next';
import colors from '../../theme/colors';

const AppStoreImage = require('@core/ui/static/images/appstore.webp');

export const GooglePlayIcon = props => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      height="1em"
      width="1em"
      {...props}
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M3.609 1.814L13.792 12 3.61 22.186a.996.996 0 01-.61-.92V2.734a1 1 0 01.609-.92zm10.89 10.893l2.302 2.302-10.937 6.333 8.635-8.635zm3.199-3.198l2.807 1.626a1 1 0 010 1.73l-2.808 1.626L15.206 12l2.492-2.491zM5.864 2.658L16.802 8.99l-2.303 2.303-8.635-8.635z" />
    </svg>
  );
};

export const AppleIcon = props => {
  return (
    <svg
      viewBox="0 0 1024 1024"
      fill="currentColor"
      height="1em"
      width="1em"
      {...props}
    >
      <path d="M747.4 535.7c-.4-68.2 30.5-119.6 92.9-157.5-34.9-50-87.7-77.5-157.3-82.8-65.9-5.2-138 38.4-164.4 38.4-27.9 0-91.7-36.6-141.9-36.6C273.1 298.8 163 379.8 163 544.6c0 48.7 8.9 99 26.7 150.8 23.8 68.2 109.6 235.3 199.1 232.6 46.8-1.1 79.9-33.2 140.8-33.2 59.1 0 89.7 33.2 141.9 33.2 90.3-1.3 167.9-153.2 190.5-221.6-121.1-57.1-114.6-167.2-114.6-170.7zm-105.1-305c50.7-60.2 46.1-115 44.6-134.7-44.8 2.6-96.6 30.5-126.1 64.8-32.5 36.8-51.6 82.3-47.5 133.6 48.4 3.7 92.6-21.2 129-63.7z" />
    </svg>
  );
};

const AppStoreBlockWrapper = styled(Card)`
  margin-top: 50px;
  background-color: ${colors.blue};
  border-radius: 8px;
  color: rgba(255, 255, 255, 0.6);
  font-family: Poppins;
  font-size: 16px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding: 50px 20px 0 30px;
  .col {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    padding-bottom: 40px;
    row-gap: 40px;
  }
  .row {
    display: flex;
    flex-direction: row;
    column-gap: 70px;
    align-items: stretch;
    .appdemo {
      max-width: 540px;
      display: flex;
      align-items: end;
    }
  }
  .stores {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    column-gap: 20px;
    row-gap: 20px;
    flex-wrap: wrap-reverse;
  }
  .title {
    font-size: 20px;
    font-weight: 600;
    color: ${colors.white};
    text-align: left;
    margin: 0;
    .country {
      white-space: nowrap;
      color: ${colors.primary};
    }
  }
  @media only screen and (max-width: 1023px) {
    .col {
      align-items: center;
      max-width: 100%;
      row-gap: 20px;
      padding-bottom: 0;
      .appdemo {
        max-width: 100%;
        display: flex;
        align-items: end;
        .img {
          max-width: 100%;
        }
      }
    }
    .title {
      text-align: center;
    }
  }
  @media only screen and (max-width: 767px) {
    column-gap: 20px;
    align-items: center;
    padding: 15px 15px 0 15px;
    .title {
      font-size: 17px;
    }
    p {
      font-size: 14px;
    }
  }
`;

const AppStoreLinkWrapper = styled.div`
  .link {
    display: flex;
    column-gap: 10px;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    border: 1px solid #185069;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.1);
    height: 80px;
    max-width: 230px;
    min-width: 230px;
    padding: 15px 20px;
    cursor: pointer;
    opacity: 1;
    &:hover {
      color: ${colors.primary};
    }
    .text {
      text-align: left;
      color: ${colors.white};
      font-size: 16px;
      font-weight: 600;
      margin: 0;
      display: flex;
      flex-direction: column;
      align-items: start;
      .appstore {
        color: ${colors.primary};
      }
    }
  }
`;

export const AppStoreLink = ({ href, download, store, icon }) => {
  return (
    <AppStoreLinkWrapper>
      <a className="link" href={href}>
        {icon}
        <p className="text">
          <span className="download">{download}</span>
          <span className="appstore">{store}</span>
        </p>
      </a>
    </AppStoreLinkWrapper>
  );
};

AppStoreLink.propTypes = {
  download: PropTypes.string,
  store: PropTypes.string,
  icon: PropTypes.element,
};

AppStoreLink.defaultProps = {};

const AppStoreBlock = ({ title, country, img, isMobile, children }) => {
  const Wrapper = isMobile
    ? ({ children }) => <>{children}</>
    : ({ children }) => <div className="row">{children}</div>;

  return (
    <AppStoreBlockWrapper>
      <Wrapper>
        <div className="col">
          <p className="title">
            {title} <span className="country">{country} !</span>
          </p>
          <div className="stores">{children}</div>
          {isMobile ? (
            <div className="appdemo">
              <img className="img" src={img} width={540} />
            </div>
          ) : null}
        </div>
        {!isMobile ? (
          <div className="appdemo">
            <img className="img" src={img} height={270} />
          </div>
        ) : null}
      </Wrapper>
    </AppStoreBlockWrapper>
  );
};

AppStoreBlock.propTypes = {
  title: PropTypes.string,
  country: PropTypes.string,
  isMobile: PropTypes.bool,
  img: PropTypes.string,
};

AppStoreBlock.defaultProps = {};

const AppStore = ({ isMobile }) => {
  const { t } = useTranslation();

  return (
    <AppStoreBlock
      title={t(`${process.env.APP_LOCALIZATION}:store.title`)}
      country={t(`${process.env.APP_LOCALIZATION}:country.label`)}
      isMobile={isMobile}
      img={AppStoreImage}
    >
      <AppStoreLink
        download={t(`${process.env.APP_LOCALIZATION}:store.download`)}
        store="App Store"
        href={process.env.LINK_IOS}
        icon={<AppleIcon height={50} width={50} />}
      />
      <AppStoreLink
        download={t(`${process.env.APP_LOCALIZATION}:store.download`)}
        store="Google Play"
        href={process.env.LINK_ANDROID}
        icon={<GooglePlayIcon height={50} width={50} />}
      />
    </AppStoreBlock>
  );
};

export default AppStore;
