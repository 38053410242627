import { briefcase } from 'react-icons-kit/fa/briefcase';
import { building } from 'react-icons-kit/fa/building';
import { car } from 'react-icons-kit/fa/car';
import { desktop } from 'react-icons-kit/fa/desktop';
import { home } from 'react-icons-kit/fa/home';
import { moreHorizontal } from 'react-icons-kit/feather/moreHorizontal';
import { soccerBallO } from 'react-icons-kit/fa/soccerBallO';
import { tag } from 'react-icons-kit/fa/tag';
import { tree } from 'react-icons-kit/fa/tree';
import { wrench } from 'react-icons-kit/fa/wrench';

const ListCategories = translator => {
  return [
    {
      options: [
        { value: 'Voitures', label: translator('category.Voitures') },
        { value: 'Motos', label: translator('category.Motos') },
        {
          value: 'Équipement Auto',
          label: translator('category.Équipement Auto'),
        },
        {
          value: 'Équipement Moto',
          label: translator('category.Équipement Moto'),
        },
        { value: 'Bateaux', label: translator('category.Bateaux') },
        { value: 'Nautisme', label: translator('category.Nautisme') },
        {
          value: 'Équipement Nautisme',
          label: translator('category.Équipement Nautisme'),
        },
        { value: 'Utilitaires', label: translator('category.Utilitaires') },
      ],
      id: 'VEHICULES',
      icon: car,
      label: translator('catTitle.Véhicules'),
    },
    {
      options: [
        {
          value: 'Ventes Immobilières',
          label: translator('category.Ventes Immobilières'),
        },
        { value: 'Locations', label: translator('category.Locations') },
        { value: 'Colocations', label: translator('category.Colocations') },
      ],
      id: 'IMMOBILIER',
      icon: building,
      label: translator('catTitle.Immobilier'),
    },
    {
      options: [
        { value: 'Informatique', label: translator('category.Informatique') },
        { value: 'Téléphonie', label: translator('category.Téléphonie') },
        {
          value: 'Console & Jeux Vidéos',
          label: translator('category.Console & Jeux Vidéos'),
        },
        { value: 'Image et son', label: translator('category.Image et son') },
      ],
      id: 'MULTIMEDIA',
      icon: desktop,
      label: translator('catTitle.Multimédia'),
    },
    {
      options: [
        { value: 'Mobilier', label: translator('category.Mobilier') },
        {
          value: 'Électroménager',
          label: translator('category.Électroménager'),
        },
        { value: 'Décoration', label: translator('category.Décoration') },
        { value: 'Bricolage', label: translator('category.Bricolage') },
        { value: 'Jardinage', label: translator('category.Jardinage') },
      ],
      id: 'MAISON',
      icon: home,
      label: translator('catTitle.Maison'),
    },
    {
      options: [
        {
          value: 'Tentes & Abris',
          label: translator('category.Tentes & Abris'),
        },
        {
          value: 'Accessoires de camping',
          label: translator('category.Accessoires de camping'),
        },
      ],
      id: 'CAMPING',
      icon: tree,
      label: translator('catTitle.Camping'),
    },
    {
      options: [
        { value: 'Vêtements', label: translator('category.Vêtements') },
        { value: 'Chaussures', label: translator('category.Chaussures') },
        {
          value: 'Vêtements Bébé',
          label: translator('category.Vêtements Bébé'),
        },
        { value: 'Puériculture', label: translator('category.Puériculture') },
        { value: 'Accessoires', label: translator('category.Accessoires') },
        {
          value: 'Montres & Bijoux',
          label: translator('category.Montres & Bijoux'),
        },
        { value: 'Vide Grenier', label: translator('category.Vide Grenier') },
      ],
      id: 'VETEMENT_BIJOUX',
      icon: tag,
      label: translator('catTitle.Vêtements & Bijoux'),
    },
    {
      options: [
        { value: 'DVD', label: translator('category.DVD') },
        { value: 'CD & Musique', label: translator('category.CD & Musique') },
        { value: 'Livres', label: translator('category.Livres') },
        { value: 'Animaux', label: translator('category.Animaux') },
        { value: 'Vélos', label: translator('category.Vélos') },
        { value: 'Sports', label: translator('category.Sports') },
        {
          value: 'Instruments de musique',
          label: translator('category.Instruments de musique'),
        },
        { value: 'Collection', label: translator('category.Collection') },
        { value: 'Jeux & Jouets', label: translator('category.Jeux & Jouets') },
      ],
      id: 'LOISIRS',
      icon: soccerBallO,
      label: translator('catTitle.Loisirs'),
    },
    {
      options: [
        {
          value: 'Prestation de services',
          label: translator('category.Prestation de services'),
        },
        {
          value: 'Cours particuliers',
          label: translator('category.Cours particuliers'),
        },
        { value: 'Covoiturage', label: translator('category.Covoiturage') },
        {
          value: 'Échanges & Troc',
          label: translator('category.Échanges & Troc'),
        },
        {
          value: 'Perdu / Trouvé',
          label: translator('category.Perdu / Trouvé'),
        },
      ],
      id: 'SERVICES',
      icon: wrench,
      label: translator('catTitle.Services'),
    },
    {
      options: [
        {
          value: "Offres d'emploi",
          label: translator("category.Offres d'emploi"),
        },
      ],
      id: 'OFFFRE_EMPLOI',
      icon: briefcase,
      label: translator('catTitle.Emploi'),
    },
    {
      options: [{ value: 'Autres', label: translator('category.Autres') }],
      id: 'Autres',
      icon: moreHorizontal,
      label: translator('catTitle.--- Autres ---'),
    },
  ];
};

export default ListCategories;
