import React from 'react';
import { useUser } from '../../contexts/UserContext';
import Dropdown from './Dropdown';
import Inline from './Inline';
import { useRouter } from 'next/router';

const Flags = ({ dropdown, ...props }) => {
  const router = useRouter();

  const { changeAppLang } = useUser();

  const { locales } = router;

  const changeLang = async lang => {
    changeAppLang(lang);
  };

  if (locales.length < 2) {
    return null;
  }

  return dropdown ? (
    <Dropdown langs={locales} onChange={changeLang} />
  ) : (
    <Inline langs={locales} onChange={changeLang} />
  );
};

export default Flags;
