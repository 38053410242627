import { useRouter } from 'next/router';
import { InlineFlags } from './style';
import React from 'react';
import { useTranslation } from 'next-i18next';

const Inline = ({ onChange, ...props }) => {
  const { t } = useTranslation();
  const { locale, locales } = useRouter();

  return (
    <InlineFlags>
      {locales.map(lng => (
        <li className={locale === lng ? 'active' : ''}>
          <a onClick={() => onChange(lng)}>{t('lang.' + lng)}</a>
        </li>
      ))}
    </InlineFlags>
  );
};

export default Inline;
