import styled from 'styled-components';
import colors from '../../theme/colors';

const FooterWrapper = styled('footer')`
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 500;
  margin-top: 10px;
  justify-content: space-between;
  align-items: center;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  background-color: #002f3f;
  color: #fff;
  .container {
    margin: 0 auto;
    display: grid;
    grid-template-columns: 8fr 4fr;
    padding-right: 15px;
    padding-left: 15px;
    row-gap: 25px;
  }
  .firstCol,
  .secondCol {
    display: flex;
    align-items: center;
  }
  .firstCol {
    justify-content: center;
    column-gap: 40px;
    flex-wrap: wrap;
  }
  .social {
    display: flex;
    column-gap: 18px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: ${colors.white};
    .text {
      white-space: nowrap;
    }
    &:hover {
      opacity: 1;
      color: ${colors.primary};
    }
  }
  @media only screen and (max-width: 1200px) {
    .firstCol,
    .secondCol {
      grid-column: span 2;
    }
    .firstCol {
      column-gap: 25px;
    }
    .secondCol {
      justify-content: center;
    }
    .footer-copyright > * + * {
      margin-left: 20px;
    }
  }
  @media (max-width: 1095px) {
    flex-direction: column;
    padding: 10px 0;
    .app-logos {
      margin-bottom: 10px;
    }
  }
  .social-icon {
    @media (max-width: 1355px) {
      zoom: 0.6;
    }
  }
  .logo-footer {
    img {
      @media (max-width: 1355px) {
        height: 50px !important;
      }
    }
  }
  @media (min-width: 490px) {
    .app-logos > div:first-child {
      margin-right: 30px;
    }
  }
  @media (max-width: 520px) {
    .app-logos > div:first-child {
      margin-right: 0;
    }
    .app-logos > * + * {
      margin-bottom: 20px;
    }
    .app-logos {
      display: flex;
      flex-direction: column-reverse;
    }
  }
  @media (max-width: 489px) {
  }
  .app-logos {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: space-evenly;
    .ios-store {
      padding: 17px;
    }
    @media (max-width: 1355px) {
      img {
        width: 200px;
      }
      .ios-store {
        padding: 13px;
        height: 77px;
      }
    }
    @media (max-width: 409px) {
      img {
        width: 160px;
      }

      .ios-store {
        padding: 10px;
        height: 65px;
      }
    }
  }
  .footer-copyright,
  .copyright {
    margin: 0;
    display: flex;
    column-gap: 30px;
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    text-align: right;
    line-height: 1.2;
    justify-content: center;
    @media (max-width: 1355px) {
      font-size: 13px !important;
    }
    @media (max-width: 409px) {
      font-size: 11px !important;
    }
    a {
      color: #fff;
      text-decoration: none;
      white-space: nowrap;
      transition: 0.15s ease-in-out;
      &:hover {
        color: #00bcd4;
      }
    }
    .social-icon {
      background-color: white;
      border-radius: 50%;
      line-height: 33px;
      font-size: 13px;
      margin-top: -12px;
      margin-left: 37px;
      a {
        padding: 5px;
        color: #0b2f3f;
        &:hover {
          color: #00bcd4;
        }
      }
    }

    @media screen and (max-width: 576px) {
      font-size: 14px;
      margin-top: 15px;
    }
  }
`;

export default FooterWrapper;
