import React from 'react';
import Link from 'next/link';
import Button from 'reusecore/src/elements/Button';
import { useAuth } from '../../contexts/AuthProvider';
import useMediaQuery from '../../helpers/mediaQuery';
import DropdownMenu from '../DropdownMenu';
import LazyLoad from 'react-lazyload';
import { MenuWrapper, MenuItemWrapper } from './style';
import profileImg from '@core/ui/static/images/user-placeholder.svg';
import {
  MESSAGERIE,
  ACCOUNT_PAGE,
  SIGNIN_PAGE,
  ACCOUNT_SETTING_PAGE,
} from '@core/ui/navigation/constant';
import messIcon from '@core/ui/static/images/ico-messagerie.svg';
import favIcon from '@core/ui/static/images/ico-fav.svg';
import AvatarInitials from '../Avatar';
import { useTranslation } from 'next-i18next';
import Icon from 'react-icons-kit';
import { list } from 'react-icons-kit/entypo/list';
import { useRouter } from 'next/router';

const imageStyle = {
  width: 40,
  height: 40,
  borderRadius: '50%',
  objectFit: 'cover',
};

const Menu = ({ className, unreadMsg, avatar, ...props }) => {
  const router = useRouter();
  const { t } = useTranslation();

  const { isLoggedIn, logout } = useAuth();

  const isTablet = useMediaQuery('(max-width: 1126px)');
  const isMobile = useMediaQuery('(max-width: 962px)');

  const handleLogout = async () => {
    if (logout instanceof Function) {
      logout();
    }
  };

  const PROFILE_MENU_ITEMS = [
    <Link href={`/${ACCOUNT_PAGE}/alerts`}>
      <a>{t('header.alerts.label')}</a>
    </Link>,
    <Link href={`/${ACCOUNT_SETTING_PAGE}`}>
      <a>{t('header.profile')}</a>
    </Link>,
    <Button
      className="logout"
      aria-label={t('logout')}
      onClick={handleLogout}
      title={t('logout')}
      variant="textButton"
      color="#123545"
      style={{
        minHeight: 'auto',
        minWidth: 'auto',
        textDecoration: 'none',
        justifyContent: 'flex-start',
        padding: '10px 0',
        width: '100%',
        textAlign: 'left',
      }}
    />,
  ];

  if (isMobile) {
    PROFILE_MENU_ITEMS.unshift(
      <Link href={`/${ACCOUNT_PAGE}/favorites`}>
        <a>{t('header.favorite.title')}</a>
      </Link>
    );
  }

  if (isTablet) {
    PROFILE_MENU_ITEMS.unshift(
      <Link href={`/${ACCOUNT_PAGE}/myClassifieds`}>
        <a>{t('myAds')}</a>
      </Link>
    );
  }

  let IconMenu;
  if (!avatar) {
    IconMenu = (
      <AvatarInitials
        customStyle={{
          height: 40,
          width: 40,
        }}
        customSpanStyle={{
          fontSize: 20,
        }}
      />
    );
  } else {
    const {
      contentUrl,
      height = null,
      source = null,
      thumbnail,
      width = null,
    } = avatar || {};
    const {
      contentUrl: thumbnailContentUrl,
      height: thumbnailHeight = null,
      source: thumbnailSource = null,
      width: thumbnailWidth = null,
    } = thumbnail || {};

    const imgSrc = thumbnail ? thumbnailContentUrl : contentUrl;
    const imgHeight = thumbnail ? thumbnailHeight : height;
    const imgSource = thumbnail ? thumbnailSource : source;
    const imgWidth = thumbnail ? thumbnailWidth : width;

    IconMenu = (
      <LazyLoad
        placeholder={<img style={imageStyle} src={profileImg} alt="Avatar" />}
        offset={300}
        once
      >
        <picture>
          {imgSource}
          <img
            src={imgSrc}
            alt="profile image"
            style={imageStyle}
            height={imgHeight}
            width={imgWidth}
          />
        </picture>
      </LazyLoad>
    );
  }

  const isActive = path => {
    return path === router.asPath ? 'active' : '';
  };

  return (
    <MenuWrapper className={className}>
      <MenuItemWrapper
        style={{ height: 21.5 }}
        className={
          isActive(`/${ACCOUNT_PAGE}/myClassifieds`) + ' item-my-classifieds'
        }
      >
        <Link href={`/${ACCOUNT_PAGE}/myClassifieds`}>
          <a title={t('myAds')}>
            <Icon
              icon={list}
              size={24}
              style={{
                color: '#0b2f3f',
                position: 'relative',
                top: -1,
                marginRight: 5,
              }}
            />
            <span className={'item-menu'}>{t('myAds')}</span>
          </a>
        </Link>
      </MenuItemWrapper>
      <MenuItemWrapper className={isActive(`/${MESSAGERIE}`)}>
        <Link href={`/${MESSAGERIE}`}>
          <a title={t('header.chat.title')}>
            {parseInt(unreadMsg) > 0 && (
              <div className="unread-msg">{unreadMsg}</div>
            )}
            <img
              src={messIcon}
              style={{
                marginRight: '10px',
                verticalAlign: 'text-top',
              }}
              alt=""
            />
            <span className={'item-menu'}>{t('header.chat.label')}</span>
          </a>
        </Link>
      </MenuItemWrapper>
      <MenuItemWrapper
        className={
          isActive(`/${ACCOUNT_PAGE}/favorites`) + ' item-my-favorites'
        }
      >
        <Link href={`/${ACCOUNT_PAGE}/favorites`}>
          <a title={t('header.favorite.title')}>
            <img
              src={favIcon}
              style={{
                marginRight: '8px',
                verticalAlign: 'text-top',
              }}
              alt=""
            />
            <span className={'item-menu'}>{t('header.favorite.label')}</span>
          </a>
        </Link>
      </MenuItemWrapper>
      {!isLoggedIn ? (
        <MenuItemWrapper paddingX="0" style={{ paddingLeft: '1rem' }}>
          <Link href={`/${SIGNIN_PAGE}`}>
            <a>
              <Button title={t('login')} className={'primary'} />
            </a>
          </Link>
        </MenuItemWrapper>
      ) : (
        <MenuItemWrapper paddingX="0" style={{ paddingLeft: '1rem' }}>
          <DropdownMenu
            dropdownDirection="right"
            content={IconMenu}
            dropdownItems={PROFILE_MENU_ITEMS}
          />
        </MenuItemWrapper>
      )}
    </MenuWrapper>
  );
};

export default Menu;
