import styled from 'styled-components';
export const SearchInputWrapper = styled.form`
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: 8px;
  background-color: #f3f3f3;
  box-shadow: 0 14px 25px 0 rgba(0, 0, 0, 0.15);
  padding: 10px;
  overflow: hidden;

  @media screen and (max-width: 767px) {
    line-height: 40px;
  }
  @media screen and (max-width: 500px) {
    .inputsearch-btn--text {
      display: none;
    }
  }
  @media screen and (max-width: 1023px) {
    //box-shadow: none;
  }
`;

export const SearchInput = styled.input`
  line-height: 49px;
  flex-grow: 1;
  border: none;
  padding-left: 1rem;
  height: 60px;
  font-size: 15px;
  color: #0b2f3f;
  font-family: Poppins;
  font-weight: 500;

  @media screen and (max-width: 767px) {
    line-height: 40px;
    height: 40px;
    max-width: calc(100% - 50px);
  }

  &:focus {
    outline: 0;
  }

  &::-webkit-input-placeholder {
    font-size: 16px;
    color: #8c8c8c;
  }

  &:-moz-placeholder {
    font-size: 16px;
    color: #8c8c8c;
  }

  &::-moz-placeholder {
    font-size: 16px;
    color: #8c8c8c;
  }
  &:-ms-input-placeholder {
    font-size: 16px;
    color: #8c8c8c;
  }
`;

export const SearchButton = styled.button`
  background-color: #00bcd4;
  border-radius: 8px;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  border: none;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  cursor: pointer;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  transition-duration: 0.4s;
  svg {
    margin-right: 10px;
  }

  a {
    font-size: 16px;
    color: #ffffff;
    font-family: 'Lato';
    font-weight: bold;
    display: flex;
    align-items: center;
  }

  &:hover {
    background-color: #002f3f;
  }

  @media screen and (max-width: 500px) {
    padding-left: 12px;
    padding-right: 12px;
    font-size: 14px;
    min-width: 50px;

    svg {
      margin-right: 5px;
    }

    a {
      font-size: 14px;
    }
  }
`;
