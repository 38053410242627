import React, { Fragment } from 'react';
import Link from 'next/link';
import SocialIconWrapper, { BorderCircle } from './socialicon.style';

const SocialIcon = props => {
  const { icons, isMobile, className } = props;
  return (
    <SocialIconWrapper className={className}>
      {icons.map((icon, index) => (
        <BorderCircle
          key={index}
          style={{
            height: isMobile ? '50px' : '41px',
            width: isMobile ? '50px' : '41px',
          }}
        >
          <Fragment key={index}>
            <span aria-label={`social-icon-${index}`}>{icon.icon}</span>
          </Fragment>
        </BorderCircle>
      ))}
    </SocialIconWrapper>
  );
};

export default SocialIcon;
